import { useState } from "react";
import "./Portfolio.css";
import logo from "./assets/img/portfolioSelfie2.jpg";
import { CustomLink } from "./components/CustomLink";
import { Section } from "./components/Section";
import { SkillItem } from "./components/SkillItem";
import { motion } from "framer-motion";

const linksLoop = (links) => {
  let x = [];
  for (let i = 0; i < links.length; i++) {
    const l = links[i];
    x.push(<CustomLink link={l} key={l.name} />);
  }
  return x;
};

const Portfolio = (props) => {
  const [animationComplete, setAnimationComplete] = useState(false);
  return (
    <div className={`intro${animationComplete ? " floating" : ""}`}>
      <motion.div
        animate={{
          opacity: 1,
          scale: 1,
          rotate: 0,
        }}
        initial={{ opacity: 0, scale: 0.5, rotate: 40 }}
        transition={{
          duration: 3,
          type: "spring",
          stiffness: 260,
          // damping: 20,
        }}
        whileTap={{
          scale: 0.8,
          rotate: -90,
        }}

        // layout="position"
      >
        <img className="logo" src={logo} alt="This is my selfie" />
      </motion.div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "5px",
          flexWrap: "wrap",
          flexShrink: 3,
        }}
      >
        {props.me.sections.map((l, i) => {
          return (
            <motion.span
              initial={{
                opacity: 0,
                scale: i % 2 === 0 ? 0.2 : 0.1,
                display: "none",
                rotate: i % 2 === 0 ? 20 : -20,
              }}
              animate={{
                opacity: 1,
                scale: 1,
                display: "initial",
                rotate: 0,
              }}
              transition={{
                duration: 0.75,
                delay: i + 1 - 0.25,
                stiffness: 260,
                damping: 20,
              }}
              key={i}
              layout="position"
            >
              <Section key={l} section={l} />
            </motion.span>
          );
          // return <Section key={element} section={element} index={idx} />;
        })}
      </div>
      <motion.div
        initial={{ opacity: 0, display: "none", x: -10 }}
        animate={{ opacity: 1, display: "initial", x: 0 }}
        transition={{
          duration: 0.75,
          delay: props.me.sections.length + 0.5,
        }}
        key={"span-motion"}
        onAnimationComplete={() => setAnimationComplete(true)}
      >
        <div
          style={{
            width: "100%",
            // height: "fit-content !important",
          }}
        >
          <h1>Here are technologies I have worked with:</h1>
          <h2
            style={{
              textDecoration: "underline",
            }}
          >
            Front End:
          </h2>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: 50,
              alignItems: "center",
              flexWrap: "wrap",
              rowGap: 5,
            }}
          >
            <SkillItem
              logo={
                "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/2300px-React-icon.svg.png"
              }
              skill={"React.js"}
            />
            <SkillItem
              logo={
                "https://storage.googleapis.com/cms-storage-bucket/0dbfcc7a59cd1cf16282.png"
              }
              skill={"Flutter"}
            />
            <SkillItem
              logo={
                "https://play-lh.googleusercontent.com/QbSSiRcodmWx6HlezOtNu3vmZeuFqkQZQQO5Y2-Zg_jBRm-mXjhlXX5yFj8iphfqzQ"
              }
              skill={"WeChat Mini Programs"}
            />
            <SkillItem
              logo={
                "https://developer.apple.com/assets/elements/icons/swiftui/swiftui-96x96_2x.png"
              }
              skill={"SwiftUI"}
            />
          </div>
        </div>

        <h2
          style={{
            marginTop: "45px",
            textDecoration: "underline",
          }}
        >
          Back End:
        </h2>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: 50,
            alignItems: "center",
            flexWrap: "wrap",
            rowGap: 5,
          }}
        >
          <SkillItem
            logo={
              "https://cdn.iconscout.com/icon/free/png-256/free-java-60-1174953.png"
            }
            skill={"Java"}
          />
          <SkillItem
            logo={"https://www.svgrepo.com/show/354245/quarkus-icon.svg"}
            skill={"Quarkus"}
          />
          <SkillItem
            logo={
              "https://upload.wikimedia.org/wikipedia/commons/thumb/1/17/GraphQL_Logo.svg/2048px-GraphQL_Logo.svg.png"
            }
            skill={"GraphQL"}
          />
          <SkillItem
            logo={
              "https://www.svgrepo.com/show/303229/microsoft-sql-server-logo.svg"
            }
            skill={"Microsoft SQL Server"}
          />
          <SkillItem
            logo={
              "https://everythingdevops.dev/content/images/2023/08/Amazon-S3-Logo.svg.png"
            }
            skill={"AWS S3"}
          />
        </div>

        <h2
          style={{
            marginTop: "45px",
            textDecoration: "underline",
          }}
        >
          Tools:
        </h2>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: 50,
            alignItems: "center",
            flexWrap: "wrap",
            rowGap: 5,
          }}
        >
          <SkillItem
            logo={
              "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Visual_Studio_Code_1.35_icon.svg/512px-Visual_Studio_Code_1.35_icon.svg.png"
            }
            skill={"Visual Studio Code"}
          />
          <SkillItem
            logo={
              "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9c/IntelliJ_IDEA_Icon.svg/1024px-IntelliJ_IDEA_Icon.svg.png"
            }
            skill={"IntelliJ IDEA"}
          />
          <SkillItem
            logo={
              "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Android_Studio_icon_%282023%29.svg/2048px-Android_Studio_icon_%282023%29.svg.png"
            }
            skill={"Android Studio"}
          />
          <SkillItem
            logo={
              "https://static-00.iconduck.com/assets.00/apps-insomnia-icon-512x512-dse2p0fm.png"
            }
            skill={"Insomnia"}
          />
          <SkillItem
            logo={"https://www.svgrepo.com/show/448271/azure-devops.svg"}
            skill={"Azure DevOps"}
          />
          <SkillItem
            logo={
              "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Git_icon.svg/2048px-Git_icon.svg.png"
            }
            skill={"Git"}
          />
          <SkillItem
            logo={"https://cdn-icons-png.flaticon.com/512/25/25231.png"}
            skill={"GitHub"}
          />
        </div>
        <br />
        <br />

        <h1>To learn more about me, here are relevant links!</h1>
        {linksLoop(props.me.links)}
      </motion.div>
    </div>
  );
};
export default Portfolio;
