import "./Section.css";

import React from "react";

export const Section = ({ section }) => {
  return (
    <div>
      <h1>{section}</h1>
    </div>
  );
};
